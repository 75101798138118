<!--  -->
<template>
  <el-container  v-if="!isLoading">
    <el-header height="250px">
      <img style="zoom:0.05;" src="../../src/assets/img/logo.png" />
      <div class="header_class">WELCOME!</div>
      <div class="header_class">欢迎登录心狗用户平台！</div>
    </el-header>
    <el-main>
      <!-- <div class="site-wrapper site-page--index">
        <div class='index'>
          <el-col :span="20">
            <el-row style="margin-top:180px">
              <el-col :span="6"><font-awesome-icon icon="fa-solid fa-user-nurse" size="4x"
                  style="color:#183153" /></el-col>
              <el-col :span="6"><font-awesome-icon icon="fa-solid fa-user-group" size="4x"
                  style="color:#183153" /></el-col>
              <el-col :span="6"><font-awesome-icon icon="fa-solid fa-user-doctor" size="4x"
                  style="color:#183153" /></el-col>
              <el-col :span="6"><font-awesome-icon icon="fa-solid fa-house-medical" size="4x"
                  style="color:#183153" /></el-col>
            </el-row>
            <el-row style="margin-top:10px">
              <el-button class="buttonClass" type="success" @click="gotoPersonal()">我的健康</el-button>
              <el-button class="buttonClass" type="success" @click="gotoFamily()">我的家庭</el-button>
              <el-button class="buttonClass" type="success" @click="gotoXingouDoctor()">心狗医生</el-button>
              <el-button class="buttonClass" type="success" @click="gotoHealthStation()">健康小屋</el-button>
            </el-row>
            <el-row style="margin-top:50px">
              <el-col :span="6"><font-awesome-icon icon="fa-solid  fa-people-roof" size="4x"
                  style="color:#183153" /></el-col>
              <el-col :span="6"><font-awesome-icon icon="fa-solid  fa-file-signature" size="4x"
                  style="color:#183153" /></el-col>
              <el-col :span="6"><font-awesome-icon icon="fa-solid  fa-building-shield" size="4x"
                  style="color:#183153" /></el-col>
              <el-col :span="6"><font-awesome-icon icon="fa-solid  fa-gears" size="4x" style="color:#183153" /></el-col>
            </el-row>
            <el-row style="margin-top:10px">
              <el-button class="buttonClass2" type="primary" @click="gotoCommunity()">健康社区</el-button>
              <el-button class="buttonClass2" type="primary" @click="gotoSignDoctor()">签约医生</el-button>
              <el-button class="buttonClass2" type="primary" @click="gotoGovern()">一屏管控</el-button>
              <el-button class="buttonClass3" type="primary" @click="gotoAdmin()">系统管理</el-button>
            </el-row>
          </el-col>
          <el-col :span="4">
            <el-row style="margin-top:180px">
              <el-col ><font-awesome-icon icon="fa-solid fa-user-nurse" size="4x"
                  style="color:#183153" /></el-col>
            </el-row>
            <el-row style="margin-top:10px">
              <el-button class="buttonClass4" type="success" @click="gotoWomenChild()">妇婴平台</el-button>
            </el-row>
          </el-col>
        </div>
        <main-footer />
      </div> -->
      <div class="site-wrapper site-page--index">
        <div class='index' style="margin-top:70px">
          <!-- 第1列 -->
          <el-col :span="6" >
            <el-row>
              <font-awesome-icon icon="fa-solid fa-user-nurse" size="4x" class="Icon" />
            </el-row>
            <el-row>
              <el-button class="buttonClass" type="success" @click="gotoPersonal()">我的健康</el-button>
            </el-row>
            <br><br><br>
            <el-row>
              <!-- <font-awesome-icon icon="fa-solid  fa-people-roof" size="4x"  class="Icon" /> -->
              <font-awesome-icon icon="fa-solid  fa-file-signature"  size="4x"  class="Icon" />

            </el-row>
            <!-- <el-row>
              <el-button class="buttonClass2" type="primary" @click="gotoCommunity()">健康社区</el-button>
            </el-row> -->
            <el-row>
              <el-button class="buttonClass2" type="primary" @click="gotoSignDoctor()">签约医生</el-button>
            </el-row>
          </el-col>
           <!-- 第2列 -->
          <el-col :span="6">
            <el-row>
              <font-awesome-icon icon="fa-solid fa-user-doctor" size="4x" class="Icon" />
            </el-row>
            <el-row>
              <!-- <el-button class="buttonClass" type="success" @click="gotoFamily()">我的家庭</el-button> -->
              <el-button class="buttonClass" type="success" @click="gotoXingouDoctor()">医生平台</el-button>
            </el-row>
            <br><br><br>
            <el-row>
              <!-- <font-awesome-icon icon="fa-solid  fa-file-signature"  size="4x"  class="Icon" /> -->
              <font-awesome-icon icon="fa-solid  fa-building-shield"  size="4x"  class="Icon" />
            </el-row>
            <el-row>
              <el-button class="buttonClass2" type="primary" @click="gotoGovern()">政府监管</el-button>
            </el-row>
          </el-col>
           <!-- 第3列 -->
          <el-col :span="6">
            <el-row>
              <font-awesome-icon icon="fa-solid fa-house-medical" size="4x"  class="Icon" />
            </el-row>
            <el-row>
              <!-- <el-button class="buttonClass" type="success" @click="gotoXingouDoctor()">心狗医生</el-button> -->
              <el-button class="buttonClass" type="success" @click="gotoHealthStation()">健康小屋</el-button>

            </el-row>
            <br><br><br>
            <el-row>
              <!-- <font-awesome-icon icon="fa-solid  fa-building-shield"  size="4x"  class="Icon" /> -->
              <font-awesome-icon icon="fa-solid  fa-people-roof" size="4x"  class="Icon" />

            </el-row>
            <el-row>
              <el-button class="buttonClass2" type="primary" @click="gotoOlder()">养老院管理</el-button>
            </el-row>
          </el-col>
           <!-- 第4列 -->
          <el-col :span="6">
            <el-row>
              <!-- <font-awesome-icon icon="fa-solid fa-user-nurse" size="4x" class="Icon" /> -->
              <font-awesome-icon icon="fa-solid fa-baby" size="4x" class="Icon" />

            </el-row>
            <el-row>
              <!-- <el-button class="buttonClass" type="success" @click="gotoHealthStation()">健康小屋</el-button> -->
              <el-button class="buttonClass" type="success" @click="gotoWomenBaby()">孕婴平台</el-button>
            </el-row>
            <br><br><br>
            <el-row>
              <font-awesome-icon icon="fa-solid  fa-people-roof" size="4x"  class="Icon" />
              <!-- <font-awesome-icon icon="fa-solid  fa-gears" size="4x"  class="Icon" /> -->

            </el-row>
            <el-row>
              <el-button class="buttonClass2" type="primary" @click="gotoHomeOlder()">居家养老</el-button>
            </el-row>
            <!-- <el-row>
              <el-button class="buttonClass3" type="primary" @click="gotoAdmin()">系统管理</el-button>
            </el-row> -->
          </el-col>
          <el-col :span="1">
            <div class="blank"></div>
          </el-col>
           <!-- 第5列 -->
          <!-- <el-col :span="4">
            <el-row>
            </el-row>
            <el-row>
            </el-row>
            <br><br><br>
            <el-row>
              <font-awesome-icon icon="fa-solid  fa-gears" size="4x"  class="Icon" />
            </el-row>
        
          </el-col> -->
        </div>
        <main-footer />
      </div>
    </el-main>
  </el-container>
</template>

<script>
import MainFooter from './main-footer'
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    MainFooter,
  },
  data() {
    //这里存放数据
    return {
      isLoading: true 
    };
  },
  //监听属性 类似于data概念
  computed: {
    moduleName: {
      get() { return this.$store.state.module.moduleName },
      set(val) { this.$store.commit('module/updateModuleName', val) }
    },
    mainHome: {
      get() { return this.$store.state.module.fromMainHome },
      set(val) { this.$store.commit('module/updateMainHome', val) }
    }
  },
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    gotoExample() {
      this.mainHome = true
      this.moduleName = 'example_module'
      this.$router.push({ name: 'login' })
    },
    gotoExample1() {
      this.mainHome = true
      this.moduleName = 'example_module1'
      this.$router.push({ name: 'login' })
    },
    gotoPersonal() {
      this.mainHome = true
      this.moduleName = 'personal_module'
      this.$router.push({ name: 'login' })
    },
    gotoHealthStation() {
      this.mainHome = true
      this.moduleName = 'health_station'
      this.$router.push({ name: 'login' })
      // window.location.href = "https://www.ai-singo.com/xingouEnterprise/login.jsp"
    },
    gotoCommunity() {
      this.mainHome = true
      this.moduleName = 'community_station'
      this.$router.push({ name: 'login' })
    },
    gotoXingouDoctor() {
      this.mainHome = true
      this.moduleName = 'doctor_station'
      this.$router.push({ name: 'login' })
    },
    gotoOlder(){
      this.mainHome = true
      this.moduleName = 'older_station'
      this.$router.push({ name: 'login' })
    },
    gotoHomeOlder(){
      this.mainHome = true
      this.moduleName = 'home_older'
      this.$router.push({ name: 'login' })
    },
    gotoFamily() {
      this.mainHome = true
      this.$alert('该功能正在开发中...', '敬请期待', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`
          });
        }
      });
    },
    gotoSignDoctor() {
      this.mainHome = true
      // this.$alert('该功能正在开发中...', '敬请期待', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${action}`
      //     });
      //   }
      // });
      window.location.href = "https://www.ai-singo.com/gover/login.jsp";
    },
    gotoGovern() {
      this.mainHome = true
      window.location.href = "http://47.106.217.34:8448";
    },
    gotoAdmin() {
      this.mainHome = true
      this.$alert('该功能正在开发中...', '敬请期待', {
        confirmButtonText: '确定',
        callback: action => {
          this.$message({
            type: 'info',
            message: `action: ${action}`
          });
        }
      });
    },
    gotoWomenBaby() {
      this.mainHome = true
      this.moduleName = 'women_baby'
      this.$router.push({ name: 'login' })
      // this.$alert('该功能正在开发中...', '敬请期待', {
      //   confirmButtonText: '确定',
      //   callback: action => {
      //     this.$message({
      //       type: 'info',
      //       message: `action: ${action}`
      //     });
      //   }
      // });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  beforeCreate() { 
    
  }, //生命周期 - 创建之前
  beforeMount() { 
    const url = window.location.href;
    const segments = url.split('/');
    console.log(segments);
    const variable = segments[3].replace('#',""); // ['https:', '', 'www.xin-gou.com', 'personal', '#', '#reloaded']
    console.log(variable); // 输出#后的参数
    setTimeout(() => {
      if (variable === "personal") {
        this.moduleName = 'personal_module'
        this.$router.push({ name: 'login' })
      }else if (variable === "doctor") {
        this.moduleName = 'doctor_station'
        this.$router.push({ name: 'login' })
      }else if (variable === "station") {
        this.moduleName = 'health_station'
        this.$router.push({ name: 'login' })
      }else if (variable === "older") {
        this.moduleName = 'older_station'
        this.$router.push({ name: 'login' })
      }else if (variable === "homeolder") {
        this.moduleName = 'home_older'
        this.$router.push({ name: 'login' })
      }else if (variable === "govern") {
        window.location.href = "http://47.106.217.34:8448";
      }else if (variable === "WomenBaby") {
        this.moduleName = 'women_baby'
        this.$router.push({ name: 'login' })
      }else if (variable === "SignDoctor") {
        window.location.href = "https://www.ai-singo.com/gover/login.jsp";
      }
      this.isLoading = false;
    }, 1000);
    
  }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() {
    if (location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less" scoped>
.site-wrapper.site-page--index {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  // background-color: rgba(176, 245, 170, 0.6);
  overflow: hidden;

  &:before {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-image: url(~@/assets/img/home_background_code.jpg);
    // background-blend-mode: lighten;
    background-size: cover;
  }

  .index {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1000px;
    transform: translate(-50%, -50%);
    text-align: center;
  }


  .buttonClass {
    width: 80%;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #428bca;
    border-color: #428bca;
  }

  .buttonClass2 {
    width: 80%;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #DC9E7B;
    border-color: #DC9E7B;
  }

  .buttonClass3 {
    width: 80%;
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    color: black;
    background-color: #D9D9D9;
    border-color: #D9D9D9;
  }

  .buttonClass4 {
    height: 50px;
    border-radius: 10px;
    font-size: 20px;
    background-color: #428bca;
    border-color: #428bca;
  }
}

.el-header {
  background-image: url(~@/assets/img/home_band.jpg);
  background-size: cover;
  color: #fff;
  text-align: right;
  padding: 20px
}

.header_class {
  text-align: center;
  font-size: 40px;
  line-height: 80px;
  font-weight: bold;
  letter-spacing: 1px;
  margin: 0;
  padding: 0;
}

.blank {
  border-radius: 4px;
  height: 18px;
}

.Icon {
  margin:5px; color:#183153
}
</style>
